import axiosInstance from "./Api";

export default {
  async getCourses(payload) {
    return await axiosInstance().get(`/common/course/all`, { params: payload });
  },

  async getCoursesByUniversityId(id, payload) {
    return await axiosInstance().get(`/common/course/${id}`, {
      params: payload,
    });
  },

  async universityDetailsWithCourses(payload) {
    const { university_id } = payload;

    return await axiosInstance().get(
      `/common/university-details/${university_id}`
    );
  },

  async getChatConnections() {
    return await axiosInstance().get(`/common/chats/connections`);
  },

  async getMessage(payload) {
    const { receiver_id } = payload;

    return await axiosInstance().get(`/common/chats/message/${receiver_id}`);
  },

  async sendMessage(payload) {
    const { receiver_id, message } = payload;
    console.log(payload);
    return await axiosInstance().post(`/common/chats/message/${receiver_id}`, {
      message,
    });
  },

  async getUserProfile() {
    return await axiosInstance().get(`/common/user-profile`);
  },

  async changePassword(payload) {
    return await axiosInstance().post(`/common/change-password`, payload);
  },

  async getAllDocumentTypes() {
    return await axiosInstance().get(`/common/document-types`);
  },

  async getAllStudentDocumentTypes() {
    return await axiosInstance().get(`/common/student-document-types`);
  },

  async getAllUniversities(payload) {
    return await axiosInstance().get(`/common/university`, { params: payload });
  },

  async getAllVisaDocumentTypes() {
    return await axiosInstance().get(`/common/visa-document-types`);
  },

  async getAllStudentVisaDocumentTypes() {
    return await axiosInstance().get(`/common/student-visa-document-types`);
  },

  async getAllApplicationStatuses() {
    return await axiosInstance().get(`/common/application-statuses`);
  },

  async getAllApplicationSubStatuses() {
    return await axiosInstance().get(`/common/application-sub-statuses`);
  },

  async getAllUsers(payload) {
    return await axiosInstance().get(`/common/all-users-and-types`, {
      params: payload,
    });
  },

  async makeAnnouncement(payload) {
    return await axiosInstance().post(`/common/announcement`, payload);
  },

  async getAllAnnouncement(payload) {
    return await axiosInstance().get(`/common/announcement`, {
      params: payload,
    });
  },

  async getHomeData(params) {
    return axiosInstance().get(`/common/home-data`, { params });
  },

  async getAssignedStudents(payload) {
    return await axiosInstance().get(`/common/assigned-student`, {
      params: payload,
    });
  },

  async getAssignedStaffToStudent(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().get(
      `/common/student-assigned-staff/${student_user_id}`
    );
  },

  async getStudentDetails(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().get(
      `/common/student-details/${student_user_id}`
    );
  },

  async getStudentApplications(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().get(
      `/common/student-applications/${student_user_id}`
    );
  },

  async getApplicationChat(payload) {
    const { sa_mapper_id, student_user_id } = payload;

    return axiosInstance().get(
      `/common/student-application-chat/${student_user_id}/${sa_mapper_id}`
    );
  },

  async sendApplicationChat(payload) {
    const { student_user_id, sa_mapper_id } = payload;

    return axiosInstance().post(
      `/common/student-application-chat/${student_user_id}/${sa_mapper_id}`,
      payload
    );
  },

  async getApplicationTimeline(payload) {
    const { student_user_id, sa_mapper_id } = payload;

    return axiosInstance().get(
      `/common/student-application-timeline/${student_user_id}/${sa_mapper_id}`,
      payload
    );
  },

  async getStudentDocuments(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().get(
      `/common/student-documents/${student_user_id}`
    );
  },

  async getStudentVisaDocuments(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().get(
      `/common/student-visa-documents/${student_user_id}`
    );
  },

  async getStudentRequiredDocs(payload) {
    const { student_user_id, course_level_id } = payload;

    return await axiosInstance().get(
      `/common/student-required-docs/${student_user_id}/${course_level_id}`
    );
  },

  async getStudentServicesAvailed(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().get(
      `/common/student-services-availed/${student_user_id}`
    );
  },

  async markFeatureCompleted(payload) {
    return await axiosInstance().post(
      `/common/mark-feature-completed`,
      payload
    );
  },

  async getCompletedFeatures(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().get(
      `/common/completed-features/${student_user_id}`
    );
  },

  async getDocUploadedForStudent(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().get(
      `/common/uploaded-doc-for-student/${student_user_id}`
    );
  },

  async removeDocForStudent(payload) {
    const { student_user_id, map_id } = payload;

    return await axiosInstance().post(
      `/common/remove-doc-for-student/${student_user_id}/${map_id}`
    );
  },

  async getVisaDocUploadedForStudent(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().get(
      `/common/uploaded-visa-doc-for-student/${student_user_id}`
    );
  },

  async removeVisaDocForStudent(payload) {
    const { student_user_id, map_id } = payload;

    return await axiosInstance().post(
      `/common/remove-visa-doc-for-student/${student_user_id}/${map_id}`
    );
  },

  async reccomendCourseToStudents(payload) {
    const { course_id, student_user_ids } = payload;

    return await axiosInstance().post(
      `/common/recommend-course-students/${course_id}`,
      { student_user_ids }
    );
  },

  async getApplications(payload) {
    return await axiosInstance().get(`/common/applications`, {
      params: payload,
    });
  },

  async removeRecommendation(payload) {
    const { student_user_id, course_id } = payload;

    return await axiosInstance().post(
      `/common/remove-recommendation/${student_user_id}/${course_id}`
    );
  },

  async getNotifications(payload) {
    return await axiosInstance().get(`/common/notifications`, {
      params: payload,
    });
  },

  async setAllNotificationsAsRead() {
    return await axiosInstance().get(`/common/notifications/set-read`);
  },

  async setAllChatMessagesAsRead(payload) {
    const { from_user_id } = payload;

    return await axiosInstance().post(
      `/common/chats/message/set-read/${from_user_id}`
    );
  },

  async getNewNotificationsAndMessages() {
    return await axiosInstance().get(`/common/new-notifications-messages`);
  },

  async getAllTrainings(payload) {
    return await axiosInstance().get(`/common/trainings`, {
      params: payload,
    });
  },

  async registerUser(payload) {
    return await axiosInstance().post(
      "/common/trainings/register_user",
      payload
    );
  },

  async getTrainingMaterial(id) {
    return await axiosInstance().get(`/common/training-material/${id}`);
  },

  async getFranchiseTrainings(payload) {
    return await axiosInstance().get(`/common/franchise-trainings`, {
      params: payload,
    });
  },

  async getFranchiseTraining(payload) {
    return await axiosInstance().get(
      `/common/franchise-training/${payload.ft_id}`,
      { params: payload }
    );
  },
  async getAcademyData(payload) {
    return await axiosInstance().get(`/common/academy-items`, {
      params: payload,
    });
  },
  async getAcademyDetails(payload) {
    return await axiosInstance().get(
      `/common/academy-detail/${payload.fa_id}`,
      { params: payload }
    );
  },

  async saveUserAddress(payload) {
    return await axiosInstance().post(`/common/save-user-address`, payload);
  },

  async getUserAddress(payload) {
    return await axiosInstance().get(`/common/user-address`, {
      params: payload,
    });
  },
  async getAllOrders(params) {
    return await axiosInstance().get(`/common/shop-orders`, {
      params,
    });
  },
  async getSingleShopOrder(payload) {
    return await axiosInstance().get(
      `/common/shop-order-details/${payload.so_id}`
    );
  },
  async saveShopOrderStatus(payload) {
    return await axiosInstance().post("/common/shop-order-status", payload);
  },
  async setQuestionResponse(payload) {
    return await axiosInstance().post(`/common/question-response`, payload);
  },

  async getSampleDocuments() {
    let response = await axiosInstance().get(`/common/document/sample`);

    return response;
  },

  async getSingleUniversityExtraInfo(payload) {
    return await axiosInstance().get(
      `/common/university-info/${payload.university_id}`
    );
  },

  async getSingleUniversityUSP(payload) {
    return await axiosInstance().get(
      `/common/university-usp/${payload.university_id}`
    );
  },

  async getAllUniversityUSP(payload) {
    return await axiosInstance().get(`/common/university-usp`, {
      params: payload,
    });
  },

  async getSingleUniversitySUR(payload) {
    return await axiosInstance().get(
      `/common/university-sur/${payload.university_id}`
    );
  },

  async getAllTasks(payload) {
    return await axiosInstance().get(`/common/tasks`, { params: payload });
  },

  async getAllAssignedTasks(payload) {
    return await axiosInstance().get(`/common/tasks-assigned`, {
      params: payload,
    });
  },

  async getSingleTask(payload) {
    return await axiosInstance().get(`/common/tasks/${payload.user_id}`);
  },

  async deleteTask(payload) {
    return await axiosInstance().get(`/common/tasks/${payload.user_id}`);
  },

  async saveTask(payload) {
    return await axiosInstance().post(`/common/tasks`, payload);
  },

  async getTaskUsers() {
    return await axiosInstance().get(`/common/tasks-users`);
  },

  // async getTaskStudents(payload) {
  //   return await axiosInstance().get(`/common/tasks-students`, {
  //     params: payload,
  //   });
  // },

  async getTaskStudents(payload) {
    return await axiosInstance().get(`/common/tasks-students`, {
      params: payload,
    });
  },

  async makeStripePayment(payload) {
    const { plan_id, student_user_id, url, cc_id, part_payment } = payload;

    let response = await axiosInstance().get(
      `/common/stripe-payment/make/${student_user_id}/${plan_id}`,
      {
        params: { url, cc_id, part_payment },
      }
    );

    return response;
  },

  async makePayUPayment(payload) {
    let { plan_id, student_user_id, cc_id, part_payment } = payload;

    // let student_user_id = 343;
    // let plan_id = 47;

    let response = await axiosInstance().get(
      `/common/payu-payment/make/${student_user_id}/${plan_id}`,
      {
        params: {
          cc_id,
          part_payment,
        },
      }
    );

    return response;
  },

  async verifyPayUPayment(payload) {
    const { transaction_id } = payload;
    let response = await axiosInstance().get(
      `/common/payu-payment/verify/${transaction_id}`
    );
    return response;
  },

  async verifyTransaction(payload) {
    const { transaction_id } = payload;
    let response = await axiosInstance().get(
      `/common/stripe-payment/verify/${transaction_id}`
    );
    return response;
  },

  async getTaskChat(payload) {
    const { task_id } = payload;
    return axiosInstance().get(`/common/task-chat/${task_id}`);
  },

  async sendTaskChat(payload) {
    const { task_id } = payload;

    return axiosInstance().post(`/common/task-chat/${task_id}`, payload);
  },

  async saveChecklistlistInfo(payload) {
    const { student_user_id } = payload;
    return await axiosInstance().post(
      `/common/save-application-checklist-info/${student_user_id}`,
      payload
    );
  },

  async setStudentStatus(payload) {
    const { status, student_user_id } = payload;

    let response = await axiosInstance().post(
      `/common/student/${student_user_id}/active-status/${status}`
    );

    return response;
  },

  async getLMSAccessToken(payload) {
    let response = await axiosInstance().get(`/common/lms-accesss-token`);
    return response;
  },

  async getAllFranchisesAndOrders(params) {
    return await axiosInstance().get(`/common/shop-orders`, {
      params,
    });
  },

  async getAllCouponCodes(payload) {
    let response = await axiosInstance().get(`/common/coupon-codes`, {
      params: payload,
    });
    return response;
  },

  async assignCouponToStudent(payload) {
    let response = await axiosInstance().post(
      `/common/assign-coupon-student`,
      payload
    );
    return response;
  },

  async getAssignedCoupons(payload) {
    let response = await axiosInstance().get(`/common/assigned-coupons`, {
      params: payload,
    });
    return response;
  },

  async getAvailableCoupons(payload) {
    let response = await axiosInstance().get(`/common/available-coupons`, {
      params: payload,
    });
    return response;
  },

  async verifyCouponCode(cc_code) {
    return await axiosInstance().get(`/common/verify-coupon/${cc_code}`);
  },

  async getAllUniversityPayments(params) {
    return await axiosInstance().get(`/common/university-payment`, {
      params,
    });
  },

  async getAllFlywireUniversities() {
    return await axiosInstance().get(`/common/all-flywire-universities`);
  },

  async getSingleFlywireUniversity(university_id) {
    return await axiosInstance().get(
      `/common/flywire-university-details/${university_id}`
    );
  },

  async getAllFlywireCurrencies() {
    return await axiosInstance().get(`/common/all-flywire-currencies`);
  },
  async saveAssessProfileByStaff(payload) {
    return await axiosInstance().post(`/common/save-assess-user`, payload);
  },

  async saveCartItem(payload) {
    return await axiosInstance().post("/common/save-cart-item", payload);
  },

  async removeCartItem(payload) {
    return await axiosInstance().post("/common/remove-cart-items", payload);
  },

  async getCartItems(payload) {
    return await axiosInstance().get(`/common/shop-cart-items`, {
      params: payload,
    });
  },

  async getServicesAndPlans(payload) {
    let response = await axiosInstance().get(`/common/services-and-plans`, {
      params: payload,
    });

    return response;
  },

  async getAllFranchises(payload) {
    return await axiosInstance().get(`/common/all-franchises`, {
      params: payload,
    });
  },

  async getAllAssociates(payload) {
    return await axiosInstance().get(`/common/all-agents`, { params: payload });
  },

  async getAllCounselorsCommon(payload) {
    return await axiosInstance().get(`/common/all-counselors`, {
      params: payload,
    });
  },

  async getAllIndustryNews(payload) {
    return await axiosInstance().get(`/common/industry-news`, {
      params: payload,
    });
  },
  async getAllMaterial(payload) {
    return await axiosInstance().get(`/common/material`, { params: payload });
  },

  async sendAudio(payload) {
    return await axiosInstance().post(`/common/speech-to-text`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
